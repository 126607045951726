@import 'style/variables';

.buttonRow {
    margin-top: 16px;
    display: flex;
    gap: 16px;
}

.actionResultSuccess {
    margin: auto;
    color: $text-success-color;
}

.actionResultError {
    margin: auto;
    color: $text-error-color;
}

.modalCloseButton {
    margin-left: auto;
    margin-top: 16px;
    display: block;
}

.modal {
    min-width: 400px;
    min-height: 156px;
}

.deviceAdoptingModal {
    min-width: 600px;
    min-height: 212px;
}

.informationSection {
    display: flex;
    gap: 8px;
    margin-bottom: 1rem;

    > label,
    .label {
        width: 200px;
    }
}

.assignToLocationForm {
    display: flex;
    flex-direction: column;
    margin: 4px 0;
}

.devicesListData {
    padding: 4px 8px;
    margin-bottom: 4px;
    width: 100%;
    min-height: 200px;
    max-width: 512px;
}

.batchName {
    min-width: 384px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.hardwareVersion {
    margin-bottom: 16px;
}

.delimeter {
    margin-left: 8px;
}
