.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    &.disabled {
        .slider {
            &:hover {
                cursor: not-allowed;
            }
        }
        opacity: 0.8;
    }

    .slider {
        position: absolute;
        border-radius: 34px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;

        &:hover {
            cursor: pointer;
        }

        &:before {
            border-radius: 50%;
            position: absolute;
            content: '';
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            transition: 0.4s;
        }
    }

    input:checked + .slider {
        background-color: #2196f3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
        transform: translateX(26px);
    }
}
